import React, { useEffect, useState } from "react";

const AndroidPage = () => {
  useEffect(() => {
    function checkIntentCompatibility() {
      const userAgent = navigator.userAgent;
      const isChrome = /Chrome\/([\d.]+)/.test(userAgent);
      const isAndroid = /Android ([^;]+);/.test(userAgent);
      if (!isChrome || !isAndroid) {
        alert(
          "Intent linking is only supported on Chrome and some other browsers on Android devices."
        );
        return false;
      }
      return true;
    }

    function redirectToApp() {
      if (checkIntentCompatibility()) {
        window.location.href =
          "intent://www.cineuns.com/openmyapp#Intent;scheme=https;package=com.cineuns.app;end";
      }
    }

    redirectToApp();
  }, []);

  return (
    <div>
      <p>
        If the app doesn't open automatically, you can{" "}
        <a href="https://play.google.com/store/apps/details?id=com.cineuns.app">
          open cineuns App in Google Play Store
        </a>
        .
      </p>
    </div>
  );
};

export default AndroidPage;
