import React, { useEffect, useRef } from "react";
import "../../Components/common/CommonListing.css";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const LatestMovies = ({ data, isMobile }) => {
  const listRef = useRef(null);
  // const history = useHistory();
  const [hoveredCard, setHoveredCard] = useState(null);

  const navigate = useNavigate();

  const handleMouseEnter = (index) => {
    setHoveredCard(index);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  const scrollLeft = () => {
    if (listRef.current) {
      listRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (listRef.current) {
      listRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  const handleClick = (item) => {
    // navigate(`/media/${item.title}/${item.id}`);
    if (isMobile) {
      navigate("/play", { state: { someData: item } });
    } else {
      navigate("/media/", { state: { someData: item } });
    }
  };

  const handleMoreClick = () => {
    //console.log("clicked");
    navigate("/showmore", {
      state: { Data: data, title: "LATEST", url_title: "latest" },
    });
  };

  // useEffect(() => {
  //   function handleResize() {
  //     setIsMobile(isMobileDevice());
  //   }

  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  return (
    <div style={{ marginLeft: "1.5rem" }}>
      <Row
        className="align-items-center"
        style={{ height: isMobile ? "35px" : "50px" }}
      >
        <Col xs={6}>
          {isMobile ? (
            <h4
              style={{
                color: "#008dc4",
                marginBottom: "-15px",
                marginLeft: "-5px",
              }}
            >
              LATEST
            </h4>
          ) : (
            <h4
              style={{
                color: "#008dc4",
                marginBottom: "-15px",
                marginLeft: "12px",
              }}
            >
              LATEST
            </h4>
          )}
        </Col>
        <Col
          xs={6}
          className="d-flex justify-content-end"
          style={{ paddingRight: "20px" }}
        >
          {isMobile ? (
            <>
              <Button
                // variant="outline-light"
                style={{
                  backgroundColor: "transparent",
                  color: "#007cc2",
                  marginBottom: "-5px",
                  marginRight: "0px",
                  borderWidth: "0px",
                  // pointerEvents: "none",
                  // Disable pointer events to prevent hover and click effects
                }}
                onClick={handleMoreClick}
                // disabled // Disable the button
              >
                More
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="dark"
                className="iconbtns back-0 rounded-circle"
                onClick={scrollLeft}
                style={{
                  marginRight: "5px",
                  border: "3px solid #655e5ea1",
                  backgroundColor: "#007cc2",
                }}
              >
                &lt;
              </Button>
              <Button
                variant="dark"
                className="iconbtns back-0 rounded-circle"
                onClick={scrollRight}
                style={{
                  border: "3px solid #655e5ea1",
                  backgroundColor: "#007cc2",
                }}
              >
                &gt;
              </Button>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col
          xs={10}
          className="movie-list-container"
          style={isMobile ? { marginLeft: "-20px" } : null}
        >
          {isMobile ? (
            <div ref={listRef} className="movie-list">
              {data &&
                data.map((item, index) => (
                  <Card
                    key={index}
                    className={` ${
                      isMobile ? "movie-item-latest-mobile" : "movie-item"
                    } ${hoveredCard === index ? "zoomed-card" : ""}`}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    // style={{ backgroundColor: "#141414" }}
                    onClick={() => handleClick(item)}
                  >
                    <Card.Img
                      variant="top"
                      src={
                        isMobile
                          ? item.browse_image
                          : item.mobile_image || "./cineuns_verti.png"
                      }
                      onError={(e) => {
                        e.target.src = isMobile
                          ? "./cineuns_horiz.png"
                          : "./cineuns_horiz.png";
                      }}
                      style={
                        isMobile
                          ? {
                              objectFit: "cover",
                              width: "8rem",
                              // height: "auto",
                              maxHeight: "100%",
                              aspectRatio: "9 / 16", // Maintain 16:9 aspect ratio
                              borderRadius: "8px",
                            }
                          : { height: "10rem" }
                      }
                    />
                    <p style={{ fontSize: "13px", color: "#007cc2" }}>
                      {item.title}
                    </p>
                  </Card>
                ))}
            </div>
          ) : (
            <div ref={listRef} className="movie-list">
              {data &&
                data.mediaContentList && data.mediaContentList.map(
                  (item, index) => (
                    <Card
                      key={index}
                      className={` ${
                        isMobile ? "movie-item-latest-mobile" : "movie-item"
                      } ${hoveredCard === index ? "zoomed-card" : ""}`}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      // style={{ backgroundColor: "#141414" }}
                      onClick={() => handleClick(item)}
                    >
                      <Card.Img
                        variant="top"
                        src={
                          isMobile
                            ? item.browse_image
                            : item.mobile_image || "./cineuns_verti.png"
                        }
                        onError={(e) => {
                          e.target.src = isMobile
                            ? "./cineuns_horiz.png"
                            : "./cineuns_horiz.png";
                        }}
                        style={
                          isMobile
                            ? {
                                objectFit: "cover",
                                width: "8rem",
                                // height: "auto",
                                maxHeight: "100%",
                                aspectRatio: "9 / 16", // Maintain 16:9 aspect ratio
                                borderRadius: "8px",
                              }
                            : { height: "10rem" }
                        }
                      />
                      <p style={{ fontSize: "13px", color: "#007cc2" }}>
                        {item.title}
                      </p>
                    </Card>
                  )
                )}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default LatestMovies;
