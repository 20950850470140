import React, { useState, useEffect } from "react";
import "./Loader.css"; // Import your CSS file for styling

const Loader = ({ isLoading }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const images = [
    "./OLAPLE/olaple_loader_1.png",
    "./OLAPLE/olaple_loader_2.png",
    "./OLAPLE/olaple_loader_3.png",
    "./OLAPLE/olaple_loader_4.png",
    "./OLAPLE/olaple_loader_5.png",
    "./OLAPLE/olaple_loader_6.png",
    "./OLAPLE/olaple_loader_7.png",
    "./OLAPLE/olaple_loader_8.png",
    "./OLAPLE/olaple_loader_9.png",
    "./OLAPLE/olaple_loader_10.png",
    "./OLAPLE/olaple_loader_11.png",
    "./OLAPLE/olaple_loader_12.png",
    "./OLAPLE/olaple_loader_13.png",
    "./OLAPLE/olaple_loader_14.png",
    "./OLAPLE/olaple_loader_15.png",
    "./OLAPLE/olaple_loader_16.png",
  ]; // List of PNG images

  // useEffect(() => {
  //   let interval;
  //   if (isLoading) {
  //     interval = setInterval(() => {
  //       setImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  //     }, 100); // Change image every 300ms (adjust as needed)
  //   } else {
  //     clearInterval(interval);
  //   }

  //   return () => clearInterval(interval);
  // }, [isLoading, images.length]);

  return (
    <div className="loader-container">
      {isLoading && (
        <div class="loader-1"></div>
        // <img
        //   src={images[imageIndex]}
        //   alt="Loading..."
        //   className="loader-image"
        // />
      )}
    </div>
  );
};

export default Loader;
